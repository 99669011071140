import React from "react";
import { StaticQuery, graphql } from "gatsby"

import ListItem from './ListItem';

import styles from './styles.module.scss';

const WeCare = () => (
  <StaticQuery
    query={graphql`
    query WeCareQuery {
      allMarkdownRemark (
        filter: { fileAbsolutePath: {regex : "\/wecare/"} }
      ) {
        nodes {
          frontmatter {
            title
            price
            image
            content
          }
        }
      }
    }`}
    render={data => {
      const items = data.allMarkdownRemark.nodes.map(item => (
        {
          title: item.frontmatter.title,
          price: item.frontmatter.price,
          content: item.frontmatter.content,
          image: item.frontmatter.image
        }
      ))
      return (
        <div id="wecare" className={styles.weCare}>
          <div className={styles.inner}>
            <h3>We care about your children</h3>
            {items.map(item => (
              <ListItem key={item.image} item={item} />
            ))}
          </div>
        </div>
      )
    }}
  />
)

export default WeCare
