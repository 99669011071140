import React from "react";
import { StaticQuery, graphql } from "gatsby"

import { Button } from 'components';

import Curve from 'images/curve.svg';

import styles from './styles.module.scss';

const Hero = () => (
  <StaticQuery
    query={graphql`
      query HomePage {
        allMarkdownRemark (
          filter: { fileAbsolutePath: {regex : "\/siteData/"} }
        ) {
          nodes {
            frontmatter {
              title
              description
              logo
              mainImage
              bannerHeading
              bannerBullets
            }
          }
        }
      }`}
    render={data => {
      const node = data.allMarkdownRemark.nodes[0].frontmatter;
      return (
      <div className={styles.hero}>
      <div className={styles.imageLeft}>
        {node.logo && <img src={node.logo} />}
      </div>
      <div className={styles.left}>
        <h1>
          {node.bannerHeading}
        </h1>
        <ul>
          {node.bannerBullets && node.bannerBullets.map(bp => (
            <li key={bp}>{bp}</li>
          ))}
        </ul>
        <Button link="#enquire">ENQUIRE ABOUT PLACEMENT</Button>
      </div>
      <div className={styles.imageRight}>
        {node.mainImage && <img src={node.mainImage} />}
      </div>
      <img src={Curve} className="curve" />
    </div>
    )}}
    />
)

export default Hero
