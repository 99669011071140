import React from "react"

import Hero from './components/Hero';
import Philosophy from './components/Philosophy';
import WeCare from './components/WeCare';
import Enquire from './components/Enquire';

const Home = () => (
 <div>
   <Hero />
   <Philosophy />
   <WeCare />
   <Enquire />
 </div>
)

export default Home
