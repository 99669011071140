import React from "react";
import { StaticQuery, graphql } from "gatsby"
import Markdown from 'react-markdown'

import Tree from 'images/tree.svg';
import styles from './styles.module.scss';

const Philosophy = () => (
  <StaticQuery
    query={graphql`
    query PhilosophyQuery {
      allMarkdownRemark (
        filter: { fileAbsolutePath: {regex : "\/siteData/"} }
      ) {
        nodes {
          frontmatter {
            philosophy
          }
        }
      }
    }`}
    render={data => {
      return (
        <div id="philosophy" className={styles.philosophy}>
        <div className={styles.left}>
            <img src={Tree} loading="lazy"/>
        </div>
        <div className={styles.right}>
            <Markdown children={data.allMarkdownRemark.nodes[0].frontmatter.philosophy} />
        </div>
      </div>
      )
    }}
  />
)

export default Philosophy
